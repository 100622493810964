import Vue from "vue";
import Vuex from "vuex";

// import ProjectController from "@/controllers/projectController";
// const projectController = new ProjectController();

Vue.use(Vuex);

function initialState() {
  return {
    projectId: "",
    projects: [{ id: "all", name: "Todos projetos" }],
    isLoadingProjects: true,
  };
}

const store = new Vuex.Store({
  state: initialState,
  mutations: {
    setProject(state, payload) {
      state.projectId = payload;
      localStorage.setItem("currentProject", payload);
    },
    async loadProjects() {
      // try {
      //   state.isLoadingProjects = true;
      //   const { body } = await projectController.getProjects();
      //   body.results.unshift({ id: "all", name: "Todos projetos" });
      //   state.projects = body.results;
      //   state.isLoadingProjects = false;
      // } catch (error) {
      //   console.log(error);
      // } finally {
      //   state.isLoadingProjects = false;
      // }
    },
    clearStore(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    changeProject({ commit }) {
      commit("loadProjects");
    },
  },
  getters: {
    currentProject(state) {
      return state.projectId;
    },
    projects(state) {
      return state.projects;
    },
    isLoadingProjects(state) {
      return state.isLoadingProjects;
    },
  },
});

export default store;
