// import UserInfo from "@/helpers/UserInfo";
import BaseController from "./baseController";

export default class AuthService {
  urlBase = "/auth";

  constructor() {
    this.base = BaseController;
  }

  static refreshingTokens = false;
  static refreshTokensPromise = null;

  async register(data) {
    return this.base._post(`${this.urlBase}/register`, data);
  }

  async login(data) {
    return this.base._post(`${this.urlBase}/login`, data);
  }

  async logout() {
    const tokens = JSON.parse(localStorage.getItem("token"));
    const body = {
      refreshToken: tokens.refresh.token,
    };
    const response = await this.base._post(`${this.urlBase}/logout`, body);
    if (response.status === 204) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    }
    return response;
  }

  async refreshTokens() {
    return this.base._post(`${this.urlBase}/refresh-tokens`);
  }

  async forgotPassword(body) {
    return this.base._post(`${this.urlBase}/forgot-password`, body);
  }

  async resetPassword(token, body) {
    return this.base._post(
      `${this.urlBase}/reset-password?token=${token}`,
      body
    );
  }

  async sendVerificationEmail() {
    return this.base._post(`${this.urlBase}/send-verification-email`);
  }

  async verifyEmail(token) {
    return this.base._post(`${this.urlBase}/verify-email?token=${token}`);
  }

  async setUserLocalStorage(data) {
    try {
      localStorage.setItem("token", JSON.stringify(data.tokens));
      // localStorage.setItem("user", JSON.stringify(data.user));
      // UserInfo.getUserInfo();
    } catch (err) {
      throw new Error(err);
    }
  }
}
