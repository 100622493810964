import BaseController from "./baseController";

class UserCredits {
  urlBase = "/user-credits";

  constructor() {
    this.base = BaseController;
  }

  async getUserCredits() {
    return this.base._get(`${this.urlBase}`);
  }

  async addUserCredits({ credit, amount, description }) {
    return this.base._post(`${this.urlBase}/add`, {
      credit,
      amount,
      description,
    });
  }

  async removeUserCredits({ credit, amount }) {
    return this.base._post(`${this.urlBase}/remove`, { credit, amount });
  }
}

const userCredits = new UserCredits();

export default userCredits;
