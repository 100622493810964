<template>
  <v-app
    class="app"
    :style="{ background: $vuetify.theme.themes[theme].background }"
    dark
  >
    <nav-bar v-if="showComponentes" :role="userRole"></nav-bar>
    <!-- <side-bar
      v-if="showComponentes"
      v-model="sidebar"
      :role="userRole"
    ></side-bar> -->
    <v-main
      :class="{
        'main-auto': sidebar && showComponentes,
        'pa-0 pt-12': $vuetify.breakpoint.mobile && showComponentes,
        'main-auto-top': showComponentes,
      }"
    >
      <RouterView />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { RouterView } from "vue-router";

// import SideBar from "@/components/SideBar.vue";

import UserInfo from "@/helpers/UserInfo";

export default {
  name: "App",

  components: {
    NavBar,
    RouterView,
  },

  data: () => ({
    sidebar: true,
  }),
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    showComponentes() {
      return this.$route.meta.requiresAuth;
    },
    userRole() {
      return UserInfo.getUserRole() === "admin" ? true : true;
    },
  },
  mounted() {

    // this.loadProjects();
    this.$vuetify.theme.dark = localStorage.getItem("theme") === "true";
    this.sidebar = localStorage.getItem("sidebar") === "true";

    window.addEventListener("storage", (event) => {
      if (event.key === "theme") {
        this.$vuetify.theme.dark = localStorage.getItem("theme") === "true";
      } else if (event.key === "sidebar") {
        this.sidebar = localStorage.getItem("sidebar") === "true";
      }
    });
  },
  methods: {
    loadProjects() {
      this.$store.commit("loadProjects");
    },
  },
  watch: {
    sidebar(value) {
      localStorage.setItem("sidebar", value);
    },
    "$vuetify.theme.dark": function(value) {
      localStorage.setItem("theme", value);
    },
  },
};
</script>

<style>
.main-auto {
  padding: 54px 0px 0px 56px !important;
}

.main-auto-top {
  padding-top: 54px !important;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.max-w-100 {
  max-width: 100px !important;
}

.max-w-150 {
  max-width: 150px !important;
}

.max-w-200 {
  max-width: 200px !important;
}
.linkBtn .v-btn__content {
  max-width: 100% !important;
  white-space: pre-wrap !important;
}

div .v-data-footer {
  justify-content: flex-end;
}

.w-full {
  width: 100% !important;
}
.h-full {
  height: 100% !important;
}
.name-cell-width {
  max-width: 150px !important;
  overflow-y: hidden !important;
}
.text-no-warp {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.theme--dark .shadow-card-dark {
  box-shadow: 0px 3px 1px -2px rgba(255, 255, 255, 0.2),
    0px 2px 2px 0px rgba(255, 255, 255, 0.14),
    0px 1px 5px 0px rgba(255, 255, 255, 0.12) !important;
}

.theme--dark .shadow-card-dark-hover:hover {
  box-shadow: 0px 5px 5px -3px rgba(255, 255, 255, 0.2),
    0px 8px 10px 1px rgba(255, 255, 255, 0.14),
    0px 3px 14px 2px rgba(255, 255, 255, 0.12) !important;
}
</style>
